import "../styles.css";
import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
import * as Tone from "tone";
import { Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";

const DEFAULTS = {
  FILTER_CUTOFF: 2000,
  FILTER_ROLLOFF: -24,
};

const FreqChannel = ({ freq, initVol, started, destination }) => {
  const padVolume = -12;
  const [volume, setVolume] = useState(initVol);
  const [on, setOn] = useState(true);
  const [partialsCount, setPartialsCount] = useState(10);

  const osc = useMemo(
    () =>
      new Tone.Oscillator({
        frequency: freq,
        phase: 0,
        type: "sine" + partialsCount.toString(),
      }),
    [freq]
  );

  useEffect(() => {
    osc.partialCount = partialsCount;
  }, [partialsCount, osc]);

  useEffect(() => {
    osc.connect(destination);
  }, [destination, osc]);

  const handleOSCToggleSwitch = (event) => {
    setOn(event.target.checked);
  };

  const onVolSlider = (event, newValue) => {
    setVolume(newValue);
  };

  const onPartialsSlider = (event, newValue) => {
    newValue = Math.floor(newValue);
    if (newValue == partialsCount) return;
    setPartialsCount(newValue);
  };

  useEffect(() => {
    if (started) osc.start();
  }, [started, osc]);

  useEffect(() => {
    osc.volume.rampTo(on ? volume + padVolume : -Infinity);
  }, [on, volume, osc]);

  return (
    <div>
      <FormGroup>
        <Box sx={{ width: 300 }}>
          <FormControlLabel
            control={<Switch checked={on} onChange={handleOSCToggleSwitch} color="primary" />}
            label={freq + " Hz"}
          />

          <Slider
            disabled={!on}
            value={partialsCount}
            onChange={onPartialsSlider}
            aria-labelledby="discrete-slider2"
            valueLabelDisplay="auto"
            color="secondary"
            min={0}
            max={20}
          />

          <Slider
            disabled={!on}
            value={volume}
            onChange={onVolSlider}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            color="secondary"
            min={-100}
            max={0}
          />
        </Box>
      </FormGroup>
    </div>
  );
};

function App() {
  const [started, setStarted] = useState(false);
  const [irLoaded, setIRLoaded] = useState(false);
  const [useSpatial, setUseSpatial] = useState(true);
  const [filterRolloff, setFilterRolloff] = useState(DEFAULTS.FILTER_ROLLOFF);
  const [filterCutoff, setFilterCutoff] = useState(DEFAULTS.FILTER_CUTOFF);

  const convolver = useMemo(
    () => (useSpatial ? new Tone.Convolver("./IR_S1R3_St.wav", setIRLoaded(true)).toDestination() : null),
    [useSpatial]
  );

  const lowPassFilter = useMemo(() => new Tone.Filter({ type: "lowpass" }), []);

  useEffect(() => {
    lowPassFilter.set({ frequency: filterCutoff, rolloff: filterRolloff });
  }, [lowPassFilter, filterCutoff, filterRolloff]);

  useEffect(() => {
    // console.log(convolver);
    Tone.getDestination().volume.value = -Infinity;
    lowPassFilter.toDestination();
  }, [irLoaded, lowPassFilter]);

  useEffect(() => {
    const targetVol = started ? 0 : -Infinity;
    Tone.getDestination().volume.rampTo(targetVol, 0.5);
  }, [started]);

  const onRolloffSlider = (event, newValue) => {
    newValue = -12 * Math.pow(2, newValue - 1);
    setFilterRolloff(newValue);
  };

  const onCutoffSlider = (event, newValue) => setFilterCutoff(newValue);

  useEffect(() => {
    Tone.getDestination().volume.rampTo(-Infinity, 0.1);

    // shut down
    setTimeout(() => {
      lowPassFilter.disconnect();
      if (convolver) lowPassFilter.connect(convolver);
      else lowPassFilter.toDestination();

      // restart
      setTimeout(() => {
        Tone.getDestination().volume.rampTo(0, 0.5);
      }, 200);
    }, 250);
  }, [convolver, lowPassFilter]);

  return (
    <>
      <Box sx={{ width: 450 }}>
        <Stack spacing={2} direction="row" sx={{ mt: 4, mb: 4 }} alignItems="center">
          <Button variant="contained" color={started ? "secondary" : "primary"} onClick={() => setStarted(!started)}>
            {started ? "Stop" : "Start"}
          </Button>

          <FormControlLabel
            control={
              <Switch checked={useSpatial} onChange={() => setUseSpatial(event.target.checked)} color="primary" />
            }
            label={"Spatial Convolution"}
          />
        </Stack>

        <Stack spacing={2} direction="row" sx={{ mt: 4, mb: 4 }} alignItems="center">
          <Slider
            aria-label="Filter Rolloff"
            defaultValue={2}
            onChange={onRolloffSlider}
            valueLabelDisplay="off"
            step={1}
            min={1}
            max={4}
            marks={[
              {
                value: 1,
                label: "-12",
              },
              {
                value: 2,
                label: "-24",
              },
              {
                value: 3,
                label: "-48",
              },
              {
                value: 4,
                label: "-96",
              },
            ]}
          />
        </Stack>

        <Stack spacing={2} direction="row" sx={{ mt: 4, mb: 4 }} alignItems="center">
          <Slider
            aria-label="Filter Cutoff"
            defaultValue={2000}
            onChange={onCutoffSlider}
            valueLabelDisplay="on"
            min={30}
            max={1000}
          />
        </Stack>
      </Box>
      SCHUMANN RESONANCES
      {/* </div> */}
      <div>
        {/* Schumann Fundamentals */}
        <FreqChannel freq={7.83} initVol={-6} started={started} destination={lowPassFilter} />
        <FreqChannel freq={14.1} initVol={-12} started={started} destination={lowPassFilter} />
        <FreqChannel freq={20.3} initVol={-18} started={started} destination={lowPassFilter} />
        {/* <FreqChannel freq={26.4} initVol={-18} started={started} convolver={convolver}/> */}
        {/* <FreqChannel freq={32.4} initVol={-26} started={started} convolver={convolver}/> */}

        {/* Danley Freqs 16,30,90 */}
      </div>
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
